import { mobile_icon } from 'assets/svg';
import { DEVICE_TYPES } from 'configs/deviceType';
import { IScreenOrientation } from 'types';

export default function ScreenOrientation({ deviceType, orientation }: IScreenOrientation) {
  return (
    <>
      {deviceType === DEVICE_TYPES.MOBILE ? (
        <div className='flex flex-col items-center justify-center gap-4 space-y-2 text-center text-white'>
          <p>Please join the class using a tablet or desktop.</p>
        </div>
      ) : (
        <div className='flex flex-col items-center justify-center gap-4 space-y-2 text-center text-white'>
          {orientation === 'portrait' && deviceType === DEVICE_TYPES.TABLET ? (
            <>
              <p>
                Yor are using a tablet in portrait mode, please switch to landscape mode or use a
                computer.
              </p>
              <img src={mobile_icon} alt='Mobile Icon' className='h-11 animate-rotate-landscape' />
            </>
          ) : (
            <>
              <p>Please join the class using a laptop or desktop.</p>
            </>
          )}
        </div>
      )}
    </>
  );
}
