export const EVALUATIONS = {
  NOTEBOOK: 'notebook_evaluations',
  PRACTICE: 'practice_evaluations',
};

export const CONTENT_TYPE = {
  ELEMENT: 'elements',
  COACH_MODULE: 'coach_module',
  EVALUATION: 'evaluation',
};

export const GUIDED_TYPE = {
  MANUAL: 'manual',
  AUTOMATIC: 'automatic',
};

export const ATTEMPT_QUESTIONS = 'Attempt questions now';
export const STAY_ON_GUIDED_SOLUTION = 'Stay on Guided Examples';
export const NO_ANSWER_SEEN = `You haven't viewed any solutions yet. Are you sure you want to Attempt questions?`;

export const CONTAINER_VARIANTS = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.2 },
  },
};

export const PERMISSION_STEPS = [
  'Look for the camera icon in browser permissions',
  "Click 'Allow' when asked about camera and microphone",
  'The page will refresh automatically',
] as const;

export const NUM_SEGMENTS = 10;
export const SMOOTHING_FACTOR = 0.3;
export const ANIMATION_DURATION = 100;

export const MAX_RETRY_ATTEMPTS = 3;

export const LOADER_TIMEOUT = 4000;
export const MEETING_TIMEOUT = 10000;

// Constants for configuration
export const DEVICE_CHECK_INTERVAL = 1000;
export const RETRY_ATTEMPTS = 3;
export const RETRY_DELAY = 1000;

// Constants for debouncing and throttling
export const TOGGLE_DEBOUNCE = 300;
export const DEVICE_CHANGE_THROTTLE = 500;

export const PERMISSION_CHECK_THROTTLE = 1000;
