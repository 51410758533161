import { Dropdown, Spinner, Tooltip } from 'flowbite-react';
import classNames from 'classnames';
import { datadogLogs } from '@datadog/browser-logs';
import { ActiveMic, ActiveVideo, ArrowIcon, Speaker, TickWhite } from 'assets/svg';
import { DeviceStates, IMediaSelectDropdownProps } from 'types';
import { useMediaDevices } from 'hooks/inClass/useMediaDevices';
import { useMeeting } from 'hooks';
import { useCallback, useEffect, useState, useRef } from 'react';

const MediaSelectDropdown = ({ mediaDevice, className }: IMediaSelectDropdownProps) => {
  const {
    audioDevices = [],
    videoDevices = [],
    speakerDevices = [],
    currentSelectedDevice,
    handleMediaSelect,
    fetchDevices,
  } = useMediaDevices();

  const { studentId, classId } = useMeeting();
  const [deviceStates, setDeviceStates] = useState<DeviceStates>({
    audioinput: { isChanging: false, selectedId: currentSelectedDevice?.audio ?? null },
    videoinput: { isChanging: false, selectedId: currentSelectedDevice?.video ?? null },
    audiooutput: { isChanging: false, selectedId: currentSelectedDevice?.speaker ?? null },
  });
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);

  const prevDeviceRef = useRef(currentSelectedDevice);

  useEffect(() => {
    const newStates = { ...deviceStates };
    let hasChanges = false;

    if (currentSelectedDevice?.speaker !== prevDeviceRef.current?.speaker) {
      newStates.audiooutput.selectedId = currentSelectedDevice?.speaker ?? null;
      hasChanges = true;
    }
    if (currentSelectedDevice?.audio !== prevDeviceRef.current?.audio) {
      newStates.audioinput.selectedId = currentSelectedDevice?.audio ?? null;
      hasChanges = true;
    }
    if (currentSelectedDevice?.video !== prevDeviceRef.current?.video) {
      newStates.videoinput.selectedId = currentSelectedDevice?.video ?? null;
      hasChanges = true;
    }

    if (hasChanges) {
      setDeviceStates(newStates);
      prevDeviceRef.current = currentSelectedDevice;
    }
  }, [currentSelectedDevice, deviceStates]);

  useEffect(() => {
    const fetchIfNeeded = async () => {
      if (!(audioDevices?.length || videoDevices?.length || speakerDevices?.length)) {
        await fetchDevices();
      }
      setIsInitialLoading(false);
    };
    fetchIfNeeded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioDevices?.length, videoDevices?.length, speakerDevices?.length]);

  const handleDeviceSelect = useCallback(
    async (device: MediaDeviceInfo) => {
      if (!device?.kind || deviceStates[device.kind]?.isChanging) return;

      setDeviceStates((prev) => ({
        ...prev,
        [device.kind]: { ...prev[device.kind], isChanging: true },
      }));

      try {
        if (device.kind === 'audiooutput') {
          const audioElements = document.querySelectorAll('audio') as NodeListOf<
            HTMLAudioElement & { setSinkId?: (deviceId: string) => Promise<void> }
          >;

          await Promise.all(
            Array.from(audioElements).map(async (audioElement) => {
              if (audioElement?.setSinkId) {
                try {
                  await audioElement.setSinkId(device.deviceId);
                } catch (error) {
                  console.error('Error setting audio output device:', error);
                }
              }
            }),
          );
        }

        await Promise.race([
          handleMediaSelect?.(device),
          new Promise((_, reject) =>
            setTimeout(() => reject(new Error('Device selection timeout')), 1000),
          ),
        ]);

        setDeviceStates((prev) => ({
          ...prev,
          [device.kind]: {
            isChanging: false,
            selectedId: device.deviceId,
          },
        }));

        datadogLogs.logger.info('Device selection successful', {
          deviceId: device.deviceId,
          deviceKind: device.kind,
          deviceLabel: device.label,
          studentId,
          classId,
        });
      } catch (error) {
        datadogLogs.logger.error('Device selection failed', {
          error,
          deviceId: device.deviceId,
          deviceKind: device.kind,
          studentId,
          classId,
        });

        setDeviceStates((prev) => ({
          ...prev,
          [device.kind]: {
            isChanging: false,
            selectedId:
              currentSelectedDevice?.[
                device.kind === 'audioinput'
                  ? 'audio'
                  : device.kind === 'videoinput'
                    ? 'video'
                    : 'speaker'
              ] ?? null,
          },
        }));
      }
    },
    [deviceStates, handleMediaSelect, studentId, classId, currentSelectedDevice],
  );

  const renderDeviceItem = (
    device: MediaDeviceInfo,
    type: 'audio' | 'video' | 'speaker',
    isSelected: boolean,
    isLoading: boolean,
  ) => {
    const deviceLabel =
      device.label ||
      `${type.charAt(0).toUpperCase() + type.slice(1)} Device ${device.deviceId?.slice(0, 5)}`;

    return (
      <div className='flex items-center w-full'>
        <div className='flex items-center min-w-0 gap-3'>
          <div className='flex items-center justify-center flex-shrink-0 w-5 h-5'>
            {isLoading ? (
              <Spinner size='sm' className='w-4 h-4' />
            ) : isSelected ? (
              <img src={TickWhite} alt='selected' className='w-4 h-4' />
            ) : (
              <div className='w-4 h-4' />
            )}
          </div>
          <Tooltip
            content={deviceLabel}
            placement='top-start'
            trigger='hover'
            animation={false}
            className='bg-gray-900 whitespace-nowrap'
          >
            <p
              className={classNames('text-sm truncate max-w-80', {
                'font-semibold text-semantic-green-400': isSelected,
                'text-white': !isSelected && !isLoading,
                'text-gray-400': isLoading,
              })}
            >
              {deviceLabel}
            </p>
          </Tooltip>
        </div>
      </div>
    );
  };

  const renderDeviceList = (
    devices: MediaDeviceInfo[] = [],
    type: 'audio' | 'video' | 'speaker',
  ) => {
    if (!devices?.length) {
      return (
        <Dropdown.Item className='px-4 py-3'>
          <div className='flex items-center text-sm text-gray-400'>No {type} devices found</div>
        </Dropdown.Item>
      );
    }

    const deviceKind =
      type === 'audio' ? 'audioinput' : type === 'video' ? 'videoinput' : 'audiooutput';
    const deviceState = deviceStates[deviceKind];

    return devices.map((device) => {
      const isSelected = deviceState?.selectedId === device.deviceId;
      const isLoading = deviceState?.isChanging;

      return (
        <Dropdown.Item
          key={`${device.deviceId}-${device.groupId}`}
          onClick={() => handleDeviceSelect(device)}
          className={classNames(
            'px-4 py-3 hover:bg-primary-700 focus:bg-primary-700 transition-all duration-200 ease-in-out',
            {
              'cursor-wait': isLoading,
              'bg-primary-800': isSelected,
            },
          )}
          disabled={isLoading}
        >
          {renderDeviceItem(device, type, isSelected, isLoading)}
        </Dropdown.Item>
      );
    });
  };

  const isAnyDeviceChanging = Object.values(deviceStates).some((state) => state.isChanging);
  const showLoading = isInitialLoading || isAnyDeviceChanging;

  const renderTrigger = () => (
    <div className='relative'>
      {showLoading && (
        <div className='absolute inset-0 flex items-center justify-center rounded-full bg-primary-900'>
          <Spinner size='sm' className='w-4 h-4' />
        </div>
      )}
      <img
        src={ArrowIcon}
        alt='select device'
        className={classNames('w-4 h-4 transition-opacity duration-200', {
          'opacity-0': showLoading,
        })}
      />
    </div>
  );

  if (isInitialLoading) {
    return (
      <div className='flex items-center justify-center w-8 h-8'>
        <Spinner size='sm' />
      </div>
    );
  }

  return (
    <Dropdown
      inline
      placement='top'
      renderTrigger={renderTrigger}
      label={undefined}
      className={classNames('bg-primary-900 shadow-2xl max-w-2xl', className)}
      theme={{
        arrowIcon: 'h-4 w-4',
        content: 'focus:outline-none',
        floating: {
          item: {
            base: 'flex w-full cursor-pointer items-center justify-start hover:bg-primary-700 focus:outline-none border-b border-primary-700 last:border-0',
          },
          style: {
            dark: 'bg-primary-900 text-white',
          },
          divider: 'h-px bg-primary-700',
          base: 'z-50 w-fit divide-y divide-primary-700 rounded-lg border border-primary-700 shadow-lg',
        },
      }}
    >
      {mediaDevice === 'video' && (
        <>
          <Dropdown.Header className='flex items-center gap-3 px-4 py-3 border-b rounded-t-lg bg-primary-800 border-primary-700'>
            <div className='flex items-center justify-center w-8 h-8 bg-white rounded-lg'>
              <img
                src={ActiveVideo}
                alt='video'
                className='w-5 h-5 p-1 bg-white rounded-full 2xl:w-6 2xl:h-6'
              />
            </div>
            <span className='text-sm font-semibold text-white'>Select a Video Device</span>
          </Dropdown.Header>
          <div className='py-1 overflow-y-auto max-h-60 scrollbar-thin scrollbar-thumb-primary-700 scrollbar-track-primary-900 hover:scrollbar-thumb-primary-600'>
            {renderDeviceList(videoDevices, 'video')}
          </div>
        </>
      )}

      {mediaDevice === 'audio' && (
        <>
          <Dropdown.Header className='flex items-center gap-3 px-4 py-3 border-b rounded-t-lg bg-primary-800 border-primary-700'>
            <div className='flex items-center justify-center w-8 h-8 bg-white rounded-lg'>
              <img
                src={ActiveMic}
                alt='mic'
                className='w-5 h-5 p-1 bg-white rounded-full 2xl:w-6 2xl:h-6'
              />
            </div>
            <span className='text-sm font-semibold text-white'>Select an Audio Device</span>
          </Dropdown.Header>
          <div className='py-1 overflow-y-auto max-h-60 scrollbar-thin scrollbar-thumb-primary-700 scrollbar-track-primary-900 hover:scrollbar-thumb-primary-600'>
            {renderDeviceList(audioDevices, 'audio')}
          </div>

          <Dropdown.Header className='flex items-center gap-3 px-4 py-3 bg-primary-800 border-y border-primary-700'>
            <div className='flex items-center justify-center w-8 h-8 bg-white rounded-lg'>
              <img
                src={Speaker}
                alt='speaker'
                className='w-5 h-5 p-1 bg-white rounded-full 2xl:w-6 2xl:h-6'
              />
            </div>
            <span className='text-sm font-semibold text-white'>Select a Speaker Device</span>
          </Dropdown.Header>
          <div className='py-1 overflow-y-auto max-h-52 scrollbar-thin scrollbar-thumb-primary-700 scrollbar-track-primary-900 hover:scrollbar-thumb-primary-600'>
            {renderDeviceList(speakerDevices, 'speaker')}
          </div>
        </>
      )}
    </Dropdown>
  );
};

export default MediaSelectDropdown;
