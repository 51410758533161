import { IDemoClassConfig } from 'types/auth';
import { IBookDemoClassResponse, IFetchAvailable, ITopicsResponse } from 'types/democlass';
import apiSlice from '.';

const DemoClassApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    /* get the demo class configuration data  */
    getDemoClassConfig: build.query<IDemoClassConfig, void>({
      query: () => ({
        url: '/utility/get-configurations',
      }),
    }),

    /* get the topic data by passing the mobile Number , gradeID and boardID  */
    getSubjectTopics: build.mutation<
      ITopicsResponse,
      { subjectId: number; gradeId: string; boardId: string }
    >({
      query: ({ subjectId, gradeId, boardId }) => ({
        url: `/demo-classes/list?gradeId=${gradeId}&boardId=${boardId}&subjectId=${subjectId}`,
      }),
    }),

    /* Book a Demo Class */
    bookDemoOrTrialClass: build.mutation<IBookDemoClassResponse, { formData: object }>({
      query: ({ formData }) => ({
        url: `/demo-classes/join`,
        method: 'POST',
        body: {
          ...formData,
          source: 'website',
        },
      }),
    }),

    /* fetch Available Demo Dates and slots  */
    fetchAvailableSlots: build.query<
      IFetchAvailable,
      { studentId: number; subjectId: number | undefined }
    >({
      query: ({ studentId, subjectId }) => ({
        url: `/v2/demo-class/available-slots?studentId=${studentId}&subjectId=${subjectId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetDemoClassConfigQuery,
  useGetSubjectTopicsMutation,
  useBookDemoOrTrialClassMutation,
  useFetchAvailableSlotsQuery,
} = DemoClassApiSlice;
