import { Label } from 'flowbite-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { datadogLogs } from '@datadog/browser-logs';

import { Mic, Video } from 'assets/svg';
import { Button } from 'components/common';

import { useMeeting } from 'hooks';
import { useSetupScreenMediaDevices } from 'hooks/inClass/useSetupScreenMediaDevices';
import { DEVICE_SOURCE } from 'configs/deviceType';
import { BrowserPermissionInstructionsProps } from 'types';

export default function BrowserPermissionInstructions({
  onPermissionGranted,
}: BrowserPermissionInstructionsProps) {
  const { studentId, classId } = useMeeting();
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [previousPermissions, setPreviousPermissions] = useState<{
    video: boolean;
    audio: boolean;
  }>({
    video: false,
    audio: false,
  });
  const { videoPermissionDenied, audioPermissionDenied, fetchDevices } =
    useSetupScreenMediaDevices();

  // Determine which devices need permission
  const devicePermission = useMemo(() => {
    if (videoPermissionDenied && audioPermissionDenied) return DEVICE_SOURCE.CAM_MIC;
    if (videoPermissionDenied) return DEVICE_SOURCE.CAMERA;
    if (audioPermissionDenied) return DEVICE_SOURCE.MICROPHONE;
    return DEVICE_SOURCE.CAM_MIC;
  }, [videoPermissionDenied, audioPermissionDenied]);

  // Dynamic permission steps based on denied permissions
  const permissionSteps = useMemo(() => {
    const iconName = {
      [DEVICE_SOURCE.CAMERA]: 'camera',
      [DEVICE_SOURCE.MICROPHONE]: 'microphone',
      [DEVICE_SOURCE.CAM_MIC]: 'camera and microphone',
    }[devicePermission];

    return [
      `Look for the ${iconName} icon in browser permissions`,
      `Click 'Allow' when asked about ${devicePermission.toLowerCase()}`,
      'The page will refresh automatically',
    ];
  }, [devicePermission]);

  const handlePermissionGranted = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await fetchDevices();
      // Call permission granted callback
      onPermissionGranted();
      // Add a small delay before reload to ensure state updates are complete
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      datadogLogs.logger.error('Error during permission granted handling', {
        error,
        studentId,
        classId,
      });
      setIsRefreshing(false);
    }
  }, [fetchDevices, onPermissionGranted, studentId, classId]);

  useEffect(() => {
    let checkInterval: NodeJS.Timeout | null = null;

    const checkMediaAccess = async () => {
      if (isRefreshing) return;
      try {
        // Request only the denied permissions
        const constraints = {
          video: videoPermissionDenied,
          audio: audioPermissionDenied,
        };

        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        const tracks = stream.getTracks();

        // Check which permissions were granted
        const currentPermissions = {
          video: tracks.some((track) => track.kind === 'video'),
          audio: tracks.some((track) => track.kind === 'audio'),
        };

        // Stop tracks after checking
        tracks.forEach((track) => track.stop());

        // Check if either permission was newly granted
        const videoNewlyGranted = !previousPermissions.video && currentPermissions.video;
        const audioNewlyGranted = !previousPermissions.audio && currentPermissions.audio;

        // If either permission was newly granted, trigger reload
        if (videoNewlyGranted || audioNewlyGranted) {
          setPreviousPermissions(currentPermissions);
          await handlePermissionGranted();
        }
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Unknown error';

        datadogLogs.logger.error('Media Permission failed', {
          type: 'Browser Permission',
          studentId,
          classId,
          error: errorMessage,
          stack: error instanceof Error ? error.stack : undefined,
        });
      }
    };

    if (!isRefreshing) {
      checkMediaAccess();
      checkInterval = setInterval(checkMediaAccess, 1000); // Increased interval to reduce rapid checks
    }

    return () => {
      if (checkInterval) {
        clearInterval(checkInterval);
      }
    };
  }, [
    classId,
    isRefreshing,
    studentId,
    videoPermissionDenied,
    audioPermissionDenied,
    previousPermissions,
    handlePermissionGranted,
  ]);

  if (isRefreshing) {
    return (
      <div className='w-[250px] lg:w-[400px] h-96 font-poppins rounded-xl bg-primary-850 border border-primary-700 p-6'>
        <div className='flex items-center gap-3 mb-6'>
          <div className='flex gap-2 p-2 rounded-lg bg-primary-800'>
            <img
              src={
                videoPermissionDenied || devicePermission === DEVICE_SOURCE.CAMERA
                  ? Video
                  : audioPermissionDenied || devicePermission === DEVICE_SOURCE.MICROPHONE
                    ? Mic
                    : Video
              }
              alt={
                videoPermissionDenied || devicePermission === DEVICE_SOURCE.CAMERA
                  ? 'Video icon'
                  : audioPermissionDenied || devicePermission === DEVICE_SOURCE.MICROPHONE
                    ? 'Microphone icon'
                    : 'Video icon'
              }
              className='w-5 h-5'
            />
          </div>
          <h2 className='text-xl text-white'>Permissions granted!</h2>
        </div>

        <div className='p-4 mb-6 bg-primary-800 rounded-xl'>
          <div className='flex items-center gap-3'>
            <div className='flex items-center justify-center w-6 h-6 text-white rounded-full bg-semantic-green-500'>
              ✓
            </div>
            <p className='capitalize text-primary-300'>{`${devicePermission} access allowed`}</p>
          </div>
          <div className='flex items-center gap-2 mt-4 text-primary-300'>
            <div className='w-4 h-4 capitalize border-2 rounded-full animate-spin border-primary-400 border-t-transparent' />
            Refreshing page...
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='fixed text-4xl origin-bottom top-3 left-20 animate-bounce'>👆</div>
      <div className='w-[250px] lg:w-[400px] h-96 font-poppins rounded-xl bg-primary-850 border border-primary-700 p-6'>
        <div className='flex flex-col justify-between flex-1 h-full gap-3'>
          <h2 className='text-base text-white capitalize lg:text-lg'>{`Allow ${devicePermission} access`}</h2>
          <div className='flex-1 p-5 bg-primary-800 rounded-xl'>
            <ol className='space-y-4'>
              {permissionSteps.map((step, index) => (
                <li key={index} className='flex items-start gap-3'>
                  <Label className='flex items-center justify-center px-2 py-1 text-[8px] lg:text-xs font-medium text-white rounded-full bg-primary-500'>
                    {index + 1}
                  </Label>
                  <span className='text-xs lg:text-sm text-primary-300'>{step}</span>
                </li>
              ))}
            </ol>
          </div>

          <div className='flex items-end justify-end flex-1 h-full gap-3'>
            <Button
              onClick={() => {
                window.location.reload();
                onPermissionGranted();
              }}
              text='Refresh page'
              className='flex-1 text-xs text-center text-white capitalize transition-colors border-2 rounded-lg lg:text-sm md:text-base bg-primary-500'
            />
          </div>
        </div>
      </div>
    </>
  );
}
