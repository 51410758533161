import { useEffect, useMemo } from 'react';
import { compact, join } from 'lodash';
import { useGetUserDataQuery } from 'store/apiSlices/login.apiSlice';
import { VITE_ZOHO_SRC_URL } from 'configs';

interface MediaDevice {
  cameras: string[];
  microphones: string[];
  speakers: string[];
  activeCamera: string | null;
  activeMicrophone: string | null;
  activeSpeaker: string | null;
}

const getFullName = (fName?: string, lName?: string): string => {
  return join(compact([fName, lName]), ' ');
};

const getMediaDevices = (): Promise<MediaDevice> => {
  if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
    console.error('Media Devices API not supported in this browser');
    return Promise.reject(new Error('Media Devices API not supported'));
  }

  return navigator.mediaDevices
    .getUserMedia({ video: true, audio: true })
    .then((stream) => {
      return navigator.mediaDevices.enumerateDevices().then((devices) => {
        const cameras: string[] = [];
        const microphones: string[] = [];
        const speakers: string[] = [];

        devices.forEach((device) => {
          if (device.kind === 'videoinput') {
            cameras.push(device.label || `Camera ${cameras.length + 1}`);
          } else if (device.kind === 'audioinput') {
            microphones.push(device.label || `Microphone ${microphones.length + 1}`);
          } else if (device.kind === 'audiooutput') {
            speakers.push(device.label || `Speaker ${speakers.length + 1}`);
          }
        });

        stream.getTracks().forEach((track) => track.stop());

        const defaultSpeaker = speakers[0] || 'No Active Speaker Source';
        return {
          cameras,
          microphones,
          speakers,
          activeCamera: cameras[0] || 'No Active Camera Source',
          activeMicrophone: microphones[0] || 'No Active Mic Source',
          activeSpeaker: defaultSpeaker,
        };
      });
    })
    .catch((error) => {
      console.error('Error accessing media devices:', error);
      return {
        cameras: [],
        microphones: [],
        speakers: [],
        activeCamera: 'No Active Camera Source',
        activeMicrophone: 'No Active Mic Source',
        activeSpeaker: 'No Active Speaker Source',
      };
    });
};

const ZohoSalesIQ = () => {
  const { data: profileData } = useGetUserDataQuery();
  const userData = useMemo(() => {
    return {
      role: 'Student',
      name: profileData
        ? getFullName(profileData?.data?.firstName, profileData?.data?.lastName)
        : '',
      email: profileData?.data?.email || '',
      contactNumber: profileData?.data?.mobileNumber || '',
    };
  }, [profileData]);

  useEffect(() => {
    const { name, email, contactNumber } = userData;

    const fetchDevices = async () => {
      const sources = await getMediaDevices();
      const initScript = document.createElement('script');
      initScript.text = `
                window.$zoho = window.$zoho || {};
                $zoho.salesiq = $zoho.salesiq || { ready: function() {} };
                $zoho.salesiq.visitor.name("${name}");
                $zoho.salesiq.visitor.email("${email}");
                $zoho.salesiq.visitor.contactnumber("${contactNumber.toString()}");
                $zoho.salesiq.visitor.info({
                    "role" : "Student",
                    "activeCamera": "${sources.activeCamera}", 
                    "activeSpeaker": "${sources.activeSpeaker}",
                    "activeMicrophone": "${sources.activeMicrophone}"
                });`;
      document.body.appendChild(initScript);
      const widgetScript = document.createElement('script');
      widgetScript.id = 'zsiqscript';
      widgetScript.src = VITE_ZOHO_SRC_URL;
      widgetScript.defer = true;

      document.body.appendChild(widgetScript);

      return () => {
        document.body.removeChild(initScript);
        document.body.removeChild(widgetScript);
      };
    };

    fetchDevices();
  }, [profileData, userData]);

  return null;
};

export default ZohoSalesIQ;
