import { Label } from 'flowbite-react';
import { Mic, Video } from 'assets/svg';
import { Button } from 'components/common';
import { DeviceOffStateProps } from 'types';

export default function DeviceOffState({ isVideoOff, isAudioOff, onEnable }: DeviceOffStateProps) {
  const getDeviceText = () => {
    if (isVideoOff && isAudioOff)
      return {
        title: 'Camera and Microphone are Off',
        icon: Video,
        buttonText: 'Enable Camera and Microphone',
      };
    if (isVideoOff)
      return {
        title: 'Camera is Off',
        icon: Video,
        buttonText: 'Enable Camera',
      };
    return {
      title: 'Microphone is Off',
      icon: Mic,
      buttonText: 'Enable Microphone',
    };
  };

  const deviceText = getDeviceText();

  const steps = [
    'Your devices are currently turned off',
    `Please enable your ${isVideoOff && isAudioOff ? 'camera and microphone' : isAudioOff ? 'microphone' : 'camera'} to verify permissions in real-time`,
    'Click the button below to enable them',
  ];

  return (
    <div className='w-full p-6 border h-96 font-poppins rounded-xl bg-primary-850 border-primary-700'>
      <div className='flex flex-col justify-between flex-1 h-full gap-3'>
        <div className='flex items-center gap-3'>
          <div className='flex gap-2 p-2 rounded-lg bg-primary-800'>
            <img src={deviceText.icon} alt='Device icon' className='w-5 h-5' />
          </div>
          <h2 className='text-xl text-white'>{deviceText.title}</h2>
        </div>

        <div className='flex-1 p-5 bg-primary-800 rounded-xl'>
          <ol className='space-y-4'>
            {steps.map((step, index) => (
              <li key={index} className='flex items-start gap-3'>
                <Label className='flex items-center justify-center px-2 py-1 text-[8px] lg:text-xs font-medium text-white rounded-full bg-primary-500'>
                  {index + 1}
                </Label>
                <span className='text-xs lg:text-sm text-primary-300'>{step}</span>
              </li>
            ))}
          </ol>
        </div>

        <div className='flex items-end justify-end flex-1 h-full gap-3'>
          <Button
            onClick={onEnable}
            text={deviceText.buttonText}
            className='flex-1 text-xs text-center text-white capitalize transition-colors border-2 rounded-lg lg:text-sm md:text-base bg-primary-500'
          />
        </div>
      </div>
    </div>
  );
}
