/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback, useEffect, useRef } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { useMeeting } from 'hooks';
import { DeviceType, PermissionCheckProps, PermissionState } from 'types';
import { listenToMediaPermissions } from 'utils';
import BrowserPermissionError from './BrowserPermissionError';
import SystemPermissionError from './SystemPermissionError';

export default function PermissionCheck({
  onPermissionGranted,
  onCheckSystemPermissions,
}: PermissionCheckProps) {
  const { studentId, classId } = useMeeting();
  const [permissionState, setPermissionState] = useState<PermissionState>('checking');
  const [deviceType] = useState<DeviceType>(() => {
    const ua = navigator.userAgent.toLowerCase();
    if (/ipad/.test(ua) || (ua.includes('mac') && navigator.maxTouchPoints > 0)) return 'iPadOS';
    if (/iphone|ipod/.test(ua)) return 'iOS';
    if (/android/.test(ua)) return 'Android';
    if (/win/.test(ua)) return 'Windows';
    if (/mac/.test(ua)) return 'macOS';
    return 'Other';
  });

  const checkingRef = useRef(false);
  const systemDeniedRef = useRef(false);

  const checkPermissions = useCallback(async () => {
    // Skip check if already checking or if system has denied permission
    if (checkingRef.current || systemDeniedRef.current) return;
    checkingRef.current = true;

    try {
      // First try camera
      try {
        const videoStream = await navigator.mediaDevices.getUserMedia({ video: true });
        videoStream.getTracks().forEach((track) => track.stop());
      } catch (error: any) {
        if (
          error.message?.includes('Permission denied by system') ||
          error.name === 'NotReadableError' ||
          error.name === 'NotFoundError'
        ) {
          setPermissionState('systemCameraDenied');
          systemDeniedRef.current = true; // Set system denied flag
          datadogLogs.logger.error('System camera permission denied', {
            error,
            deviceType,
            studentId,
            classId,
          });
          return;
        } else if (error.name === 'NotAllowedError') {
          setPermissionState('browserDenied');
          return;
        }
      }

      //  microphone
      try {
        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        audioStream.getTracks().forEach((track) => track.stop());
      } catch (error: any) {
        if (
          error.message?.includes('Permission denied by system') ||
          error.name === 'NotReadableError' ||
          error.name === 'NotFoundError'
        ) {
          setPermissionState('systemMicDenied');
          systemDeniedRef.current = true; // Set system denied flag
          datadogLogs.logger.error('System microphone permission denied', {
            error,
            deviceType,
            studentId,
            classId,
          });
          return;
        } else if (error.name === 'NotAllowedError') {
          setPermissionState('browserDenied');
          return;
        }
      }

      // Check if camera is in use by trying each video device
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((device) => device.kind === 'videoinput');

      for (const device of videoDevices) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: { exact: device.deviceId } },
          });
          stream.getTracks().forEach((track) => track.stop());
        } catch (error: any) {
          if (
            error.name === 'NotReadableError' ||
            error.message.includes('Could not start video source')
          ) {
            datadogLogs.logger.info('Camera in use detected', {
              deviceId: device.deviceId,
              deviceType,
              studentId,
              classId,
            });
            return;
          }
        }
      }

      // If we get here, all permissions are granted and no camera is in use
      setPermissionState(null);
      onPermissionGranted();
    } catch (error: any) {
      datadogLogs.logger.error('General permission check error', {
        error,
        deviceType,
        studentId,
        classId,
      });
      if (error.name === 'NotAllowedError') {
        setPermissionState('browserDenied');
      }
      // Rest of the permission checks...
    } finally {
      checkingRef.current = false;
    }
  }, [deviceType, onPermissionGranted, studentId, classId]);

  useEffect(() => {
    if (permissionState === 'checking') {
      const timeout = setTimeout(() => {
        checkPermissions();
        onCheckSystemPermissions();
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [checkPermissions, permissionState, onCheckSystemPermissions]);

  // Only set up listeners if system hasn't denied permissions
  useEffect(() => {
    checkPermissions();
    onCheckSystemPermissions();

    // Only set up listeners if system hasn't denied permissions
    if (!systemDeniedRef.current) {
      const cleanup = listenToMediaPermissions((permissions) => {
        if (!permissions.videoPermissionDenied || !permissions.audioPermissionDenied) {
          checkPermissions();
          onCheckSystemPermissions();
        }
      });

      return () => {
        cleanup.then((cleanupFn) => cleanupFn);
      };
    }
  }, [checkPermissions, onCheckSystemPermissions]);

  const openSystemSettings = useCallback(
    (device: 'camera' | 'microphone') => {
      switch (deviceType) {
        case 'Windows':
          window.open(
            device === 'camera' ? 'ms-settings:privacy-webcam' : 'ms-settings:privacy-microphone',
          );
          break;
        case 'macOS':
          window.open('x-apple.systempreferences:com.apple.preference.security?Privacy');
          break;
        // Mobile platforms don't support direct settings links
        default:
          datadogLogs.logger.info('System settings not directly accessible for this device type', {
            deviceType,
            device,
          });
      }
    },
    [deviceType],
  );
  // Render appropriate UI based on permission state
  if (permissionState === 'checking') {
    return (
      <div className='flex flex-col items-center justify-center gap-4 border h-96 rounded-xl bg-primary-850 border-primary-700'>
        <div className='w-12 h-12 border-4 rounded-full border-primary-400 border-t-transparent animate-spin' />
        <p className='text-base text-primary-400'>Checking permissions...</p>
      </div>
    );
  }

  if (permissionState === 'browserDenied') {
    return <BrowserPermissionError onPermissionGranted={onPermissionGranted} />;
  }
  if (permissionState === 'systemCameraDenied' || permissionState === 'systemMicDenied') {
    const device = permissionState === 'systemCameraDenied' ? 'camera' : 'microphone';
    return (
      <SystemPermissionError
        device={device}
        deviceType={deviceType}
        onPermissionGranted={onPermissionGranted}
        onOpenSettings={openSystemSettings}
      />
    );
  }

  return null;
}
