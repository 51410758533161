import { useCallback, useEffect, useRef, useState } from 'react';

interface AudioAnalyzerResult {
  analyserRef: React.RefObject<AnalyserNode | null>;
  audioLevel: number;
  error: string | null;
  isInitialized: boolean;
  isMicrophoneWorking: boolean;
  resetStream: () => void;
}

export const useAudioAnalyzer = ({
  deviceId,
  isEnabled,
}: {
  deviceId: string | null;
  isEnabled: boolean;
}): AudioAnalyzerResult => {
  const [error, setError] = useState<string | null>(null);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isMicrophoneWorking, setIsMicrophoneWorking] = useState<boolean>(false);
  const [audioLevel, setAudioLevel] = useState<number>(0);

  // Refs
  const analyserRef = useRef<AnalyserNode | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const dataArrayRef = useRef<Float32Array | null>(null);
  const rafRef = useRef<number>();
  const isActiveRef = useRef<boolean>(false);

  const cleanup = async () => {
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current);
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }
    if (audioContextRef.current?.state === 'running') {
      await audioContextRef.current.close();
    }
    analyserRef.current = null;
    dataArrayRef.current = null;
    setAudioLevel(0);
    setIsMicrophoneWorking(false);
  };

  const initAudio = useCallback(async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const microphoneExists =
        devices.some((device) => device.kind === 'audioinput' && device.deviceId === deviceId) ||
        devices[0]?.deviceId;

      if (!microphoneExists) {
        throw new Error('Microphone device not found');
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: deviceId || '',
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
      });

      if (!isActiveRef.current) return;

      if (!stream || !stream.active || stream.getAudioTracks().length === 0) {
        throw new Error('No active audio tracks found');
      }

      const audioContext = new AudioContext();
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 512;
      analyser.smoothingTimeConstant = 0.1;

      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);

      streamRef.current = stream;
      audioContextRef.current = audioContext;
      analyserRef.current = analyser;
      dataArrayRef.current = new Float32Array(analyser.frequencyBinCount);

      setIsInitialized(true);
      setError(null);
      setIsMicrophoneWorking(true);

      return true;
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Audio initialization failed');
      setIsMicrophoneWorking(false);
      setIsInitialized(false);
      return false;
    }
  }, [deviceId]);

  const resetStream = useCallback(async () => {
    await cleanup();
    setIsInitialized(false);
    setIsMicrophoneWorking(false);
    setAudioLevel(0);
    isActiveRef.current = true;
    await initAudio();
  }, [initAudio]);

  const analyzeAudio = useCallback(() => {
    if (!analyserRef.current || !dataArrayRef.current || !isActiveRef.current) {
      setAudioLevel(0);
      return;
    }

    analyserRef.current.getFloatTimeDomainData(dataArrayRef.current);
    const rms = Math.sqrt(
      dataArrayRef.current.reduce((sum, val) => sum + val * val, 0) / dataArrayRef.current.length,
    );

    const normalizedLevel = Math.min(Math.pow(rms * 4, 0.8), 1);
    setAudioLevel(normalizedLevel);
    setIsMicrophoneWorking(normalizedLevel > 0);

    rafRef.current = requestAnimationFrame(analyzeAudio);
  }, []);

  useEffect(() => {
    if (!deviceId || !isEnabled) {
      setIsMicrophoneWorking(false);
      cleanup();
      return;
    }

    isActiveRef.current = true;

    initAudio();

    return () => {
      isActiveRef.current = false;
      cleanup();
    };
  }, [analyzeAudio, deviceId, initAudio, isEnabled]);

  return {
    analyserRef,
    audioLevel,
    error,
    isInitialized,
    isMicrophoneWorking,
    resetStream,
  };
};

export default useAudioAnalyzer;
