import { useCallback, useEffect, useRef, useState } from 'react';
import { AudioLevelMeterProps } from 'types';

export const NUM_SEGMENTS = 15;

export default function AudioLevelMeter({
  analyserNode,
  className = '',
  isWorking,
  isLoading = false,
  show = true,
}: AudioLevelMeterProps) {
  const [audioLevel, setAudioLevel] = useState<number>(0);
  const [smoothedLevel, setSmoothedLevel] = useState<number>(0);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const rafRef = useRef<number>();
  const prevTimeRef = useRef<number>(performance.now());
  const prevLevelRef = useRef<number>(0);

  // Fixed type checking for shouldAnimate
  const shouldAnimate = Boolean(show && isWorking && !isLoading && analyserNode);

  /**
   Initialize component and handle cleanup
   */
  useEffect(() => {
    setIsInitialized(true);
    return () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
    };
  }, []);

  /**
   Animate audio level changes with smooth transitions
   */

  const analyzeAudio = useCallback(() => {
    if (!analyserNode || !shouldAnimate) {
      setAudioLevel(0);
      return;
    }

    const dataArray = new Float32Array(analyserNode.frequencyBinCount);
    analyserNode.getFloatTimeDomainData(dataArray);

    const rms = Math.sqrt(dataArray.reduce((sum, val) => sum + val * val, 0) / dataArray.length);

    const normalizedLevel = Math.min(Math.pow(rms * 4, 0.8), 1);
    setAudioLevel(normalizedLevel);

    rafRef.current = requestAnimationFrame(analyzeAudio);
  }, [analyserNode, shouldAnimate]);

  const updateLevel = useCallback(
    (timestamp: number) => {
      if (!shouldAnimate) {
        setSmoothedLevel(0);
        return;
      }

      const deltaTime = timestamp - prevTimeRef.current;
      const smoothing = Math.min(deltaTime / 16.67, 1) * 0.3;

      const currentLevel = Math.max(0, Math.min(1, audioLevel));
      const newLevel = prevLevelRef.current + (currentLevel - prevLevelRef.current) * smoothing;

      setSmoothedLevel(newLevel);
      prevLevelRef.current = newLevel;
      prevTimeRef.current = timestamp;

      rafRef.current = requestAnimationFrame(updateLevel);
    },
    [audioLevel, shouldAnimate],
  );

  /**
    Handle animation frame updates and cleanup
   */
  useEffect(() => {
    if (!shouldAnimate) {
      setSmoothedLevel(0);
      setAudioLevel(0);
      prevLevelRef.current = 0;
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
      return;
    }

    analyzeAudio();
    rafRef.current = requestAnimationFrame(updateLevel);

    return () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
    };
  }, [shouldAnimate, analyzeAudio, updateLevel]);

  /**
    Determine segment color based on its index and state
   */
  const getSegmentColor = useCallback(
    (index: number, isActive: boolean) => {
      if (!isInitialized || !analyserNode) return 'bg-gray-800';
      if (isLoading) return 'bg-gray-700';
      if (!isActive || !isWorking) return 'bg-gray-800';

      const segmentThreshold = index / NUM_SEGMENTS;
      if (segmentThreshold < 0.6) return 'bg-green-500';
      if (segmentThreshold < 0.8) return 'bg-orange-500';
      return 'bg-red-500';
    },
    [isInitialized, isLoading, isWorking, analyserNode],
  );

  if (!show || !analyserNode) {
    return null;
  }

  return (
    <div
      className={`flex gap-px items-center transition-opacity duration-200 
      ${isInitialized ? 'opacity-100' : 'opacity-0'} ${className}`}
    >
      {Array.from({ length: NUM_SEGMENTS }, (_, index) => {
        const threshold = Math.pow(index / NUM_SEGMENTS, 1.2);
        const isSegmentActive = smoothedLevel >= threshold && shouldAnimate;
        const bgColor = getSegmentColor(index, isSegmentActive);

        return (
          <div
            key={index}
            className={`
                  relative h-2 w-2 rounded
                  ${bgColor}
                  ${isInitialized ? 'opacity-100' : 'opacity-0'}
                  ${isSegmentActive ? 'scale-y-110' : 'scale-y-100'}
                  transition-all duration-75 ease-out
                `}
            style={{ transitionDelay: `${index * 15}ms` }}
          />
        );
      })}
    </div>
  );
}
