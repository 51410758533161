import { useDyteSelector } from '@dytesdk/react-web-core';
import { useState } from 'react';
import StudentSetupScreen from 'components/inClass/StudentSetupScreen';
import InClass from 'components/inClass/InClass';
import HuddleTransition from 'components/inClass/HuddleTransition';
import { BouncingDotsLoader } from 'components/common';
import { useMeeting } from 'hooks';

const Meeting = () => {
  const [showLoader] = useState<boolean>(false);
  const roomJoined = useDyteSelector((meeting) => meeting.self.roomJoined);
  const { state } = useMeeting();

  return (
    <>
      {state === 'connected-meeting' || showLoader ? (
        <div className='flex flex-col items-center justify-center text-white bg-primary-800 w-[100vw] h-[100vh] gap-10'>
          <HuddleTransition />
          <BouncingDotsLoader />
        </div>
      ) : !roomJoined ? (
        <StudentSetupScreen />
      ) : (
        <InClass />
      )}
    </>
  );
};

export default Meeting;
