import { IControls } from 'types';
import InClassButton from '../Button';

export default function SetupScreenControls({
  handleClose,
  isStudentInTime,
  mediaTestPassed,
  joinMeeting,
}: IControls) {
  return (
    <div className='flex gap-x-4 gap-y-2 bg-primary-800 mx-4 sm:mx-6 rounded-xl items-center justify-between px-2 sm:px-4 py-2 border border-[#fff5] max-w-full'>
      <div>
        <InClassButton title='Cancel' color='darkBlue' rounded={false} onClick={handleClose} />
      </div>
      <div className='flex items-center justify-center'>
        <InClassButton
          color='green'
          title='Join Now'
          disabled={isStudentInTime && !mediaTestPassed}
          rounded={false}
          onClick={async () => {
            joinMeeting({ isMediaTest: false });
          }}
          className='ml-4'
        />
      </div>
    </div>
  );
}
