import { Button } from 'components/common';
import { Label } from 'flowbite-react';
import { SystemPermissionErrorProps } from 'types';
import { getSystemInstructions } from 'utils/inClass/deviceUtils';

export default function SystemPermissionError({
  device,
  deviceType,
  onPermissionGranted,
  onOpenSettings,
}: SystemPermissionErrorProps) {
  const canOpenSettings = () => {
    return (
      deviceType === 'Windows' ||
      deviceType === 'macOS' ||
      deviceType == 'iPadOS' ||
      deviceType === 'iOS'
    );
  };

  return (
    <div className='w-[250px] lg:w-[400px] h-96 font-poppins rounded-xl bg-primary-850 border border-primary-700 p-6'>
      <div className='flex flex-col justify-between flex-1 h-full gap-3'>
        {/* Header with icons */}
        <h2 className='text-base text-white lg:text-lg'>
          {['iOS', 'iPadOS', 'Android'].includes(deviceType)
            ? 'Follow these steps to enable access:'
            : `Enable ${device} access in system settings:`}
        </h2>
        {/* Instructions container */}
        <div className='flex-1 p-5 bg-primary-800 rounded-xl'>
          <ol className='space-y-4'>
            {getSystemInstructions(device, deviceType).map((instruction, index) => (
              <li key={index} className='flex items-start gap-3'>
                <Label className='flex items-center justify-center px-2 py-1 text-[8px] lg:text-xs font-medium text-white rounded-full bg-primary-500'>
                  {index + 1}
                </Label>
                <span className='text-xs lg:text-sm text-primary-300'>{instruction}</span>
              </li>
            ))}
          </ol>
        </div>

        {/* Action buttons */}
        <div className='flex items-end justify-end flex-1 h-full gap-3'>
          <Button
            onClick={() => (window.location.reload(), onPermissionGranted())}
            text={'Refresh'}
            className='flex-1 text-xs text-center text-white capitalize transition-colors border-2 rounded-lg lg:text-sm md:text-base bg-secondary-500'
          />
          {canOpenSettings() && (
            <Button
              onClick={() => onOpenSettings(device)}
              text={'Open ⚙'}
              className='flex-1 text-xs text-center text-white capitalize transition-colors border-2 rounded-lg lg:text-sm md:text-base bg-primary-500'
            />
          )}
        </div>
      </div>
    </div>
  );
}
