import { BrowserInfo, MediaPermissions } from 'types';

// Extend the PermissionName type to include "microphone" and "camera"
type ExtendedPermissionName = PermissionName | 'microphone' | 'camera';

function isFirefox() {
  return navigator?.userAgent?.toLowerCase()?.includes('firefox');
}

export const getBrowserInfo = (): BrowserInfo => {
  const ua = navigator.userAgent.toLowerCase();
  let browserName: BrowserInfo['name'] = 'other';
  let browserVersion: string = '0';

  if (ua.includes('chrome')) {
    browserName = 'chrome';
    browserVersion = ua.match(/chrome\/(\d+)/)?.[1] || '0';
  } else if (ua.includes('firefox')) {
    browserName = 'firefox';
    browserVersion = ua.match(/firefox\/(\d+)/)?.[1] || '0';
  } else if (ua.includes('safari') && !ua.includes('chrome')) {
    browserName = 'safari';
    browserVersion = ua.match(/version\/(\d+)/)?.[1] || '0';
  } else if (ua.includes('edge')) {
    browserName = 'edge';
    browserVersion = ua.match(/edge\/(\d+)/)?.[1] || '0';
  }

  return {
    name: browserName,
    version: browserVersion,
    isMobile: /mobile|android|iphone|ipad|ipod/.test(ua),
    supportsPermissionsAPI: 'permissions' in navigator,
    supportsMediaDevices: 'mediaDevices' in navigator,
    supportsEnumerateDevices: 'enumerateDevices' in navigator.mediaDevices,
  };
};

// Function to check both audio and video permissions
export const checkMediaPermissions = async (): Promise<MediaPermissions> => {
  const permissions: MediaPermissions = {
    audioPermissionDenied: false,
    videoPermissionDenied: false,
  };

  if (isFirefox()) {
    const permissions: MediaPermissions = {
      audioPermissionDenied: false,
      videoPermissionDenied: false,
    };
    try {
      const devices = await navigator?.mediaDevices?.enumerateDevices();
      const device = devices?.[0];

      await navigator?.mediaDevices?.getUserMedia({
        audio: true,
        video: { deviceId: device?.deviceId },
      });
    } catch (error) {
      // Error handling for permission denied or other media errors
      if (error instanceof Error) {
        permissions.audioPermissionDenied =
          error.name === 'NotAllowedError' || error.name === 'SecurityError';
        permissions.videoPermissionDenied =
          error.name === 'NotAllowedError' || error.name === 'SecurityError';
      }
    }
  } else {
    try {
      const audioPermission = await navigator?.permissions?.query({
        name: 'microphone' as PermissionName,
      });
      permissions.audioPermissionDenied = audioPermission?.state === 'denied';
    } catch (error) {
      console.warn('Microphone permission query failed, attempting to access media.');
    }

    try {
      const cameraPermission = await navigator?.permissions?.query({
        name: 'camera' as PermissionName,
      });
      permissions.videoPermissionDenied = cameraPermission?.state === 'denied';
    } catch (error) {
      console.warn('Camera permission query failed, attempting to access media.');
    }
  }
  return permissions;
};

// Function to check and listen to permission changes
export const listenToMediaPermissions = async (
  onChange: (permissions: MediaPermissions) => void,
): Promise<void> => {
  const permissionNames: ExtendedPermissionName[] = ['microphone', 'camera'];
  if (isFirefox()) {
    onChange(await checkMediaPermissions());
  } else {
    for (const type of permissionNames) {
      try {
        const permission = await navigator?.permissions?.query({ name: type as PermissionName });
        permission.onchange = async () => {
          onChange(await checkMediaPermissions());
        };
      } catch (error) {
        console.error(`Failed to query permissions for ${type}`, error);
      }
    }
  }
};
